/*************************************************
  1) Main Container
**************************************************/
.manage-container-work-report {
    margin-left: 64px;
    padding: 20px;
    text-align: center;
    width: 100%;
    
    color: #19485F;                 /* Brand text color */
    box-sizing: border-box;
  }
  
  /*************************************************
    2) Header with Decorative Underline
  **************************************************/
  .manage-container-work-report h1 {
    position: relative;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #19485F;
    display: inline-block;
  }
  
  .manage-container-work-report h1::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 3px;
    background: #19485F;
    border-radius: 4px;
  }
  
  /*************************************************
    3) Grid Layout for Cards
  **************************************************/
  .manage-grid-work-report {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px !important;
  }
  
  /*************************************************
    4) Card Styling
  **************************************************/
  .manage-card-work-report {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40rem;
    width: 30%;
    padding: 3rem 2rem;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
    border-radius: 1.5rem;
    transition: all 0.2s ease-in-out;
    text-align: center;
    color: #19485F; /* Brand text color for content inside cards */
  }
  
  .manage-card-work-report:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 12px;
    transform: scale(1.05);
  }
  
  .manage-card-work-report h3 {
    margin-bottom: 1rem;
    font-size: 1.75rem;
    /* Use brand color for headings; adjust if variables exist */
    color: #19485F;
  }
  
  .manage-card-work-report p {
    font-size: 1.125rem;
    font-weight: 400;
    /* Use brand color for paragraphs; adjust if variables exist */
    color: #19485F;
  }
  
  /*************************************************
    5) Responsive Adjustments
  **************************************************/
  @media (max-width: 768px) {
    .manage-container-work-report {
      margin: 34px 0 0 auto;
    }
    
    .manage-grid-work-report {
      margin: 4rem 0;
    }
  
    .manage-card-work-report {
      width: 100%;
    }
  }
  