/*************************************************
  1) Modal Backdrop
**************************************************/
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/*************************************************
  2) Modal Content Container
**************************************************/
.modal-content {
 
  padding: 2rem;
  border-radius: 10px;
  width: 600px;
  max-width: 90%;
  text-align: center;
  max-height: 100vh;
  overflow: hidden;
  color: #19485F;        /* Brand text color */
  box-shadow: 0 4px 8px rgba(0,0,0,0.25);
}

/*************************************************
  3) Typography & Form Elements
**************************************************/
.modal-content label {
  color: #19485F;
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.modal-content h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #19485F;
}

.modal-content input,
.modal-content select {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/*************************************************
  4) Buttons within Modal
**************************************************/
.modal-content button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  margin: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Primary action button (first-of-type) with gradient */
.modal-content button:first-of-type {
  background: linear-gradient(to right, #19485F, #2e6c83);
  color: white;
}

.modal-content button:first-of-type:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(25, 72, 95, 0.3);
}

/* Secondary/Close button style (last-of-type) */
.modal-content button:last-of-type {
  background: #f0f0f0;
  color: #333;
}

/*************************************************
  5) Existing and Note Styles
**************************************************/
.existing, .note {
  color: #333;
}

/*************************************************
  6) List Styling for Classes/Batches
**************************************************/
.class-list-manage {
  list-style-type: none;
  padding: 0;
  max-height: 220px;
  overflow-y: auto;
  margin-top: 10px;
  border: 1px solid #ddd;
}

.class-list-manage li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.class-name {
  color: #19485F;
  font-weight: 500;
}

/*************************************************
  7) Delete Button Styling
**************************************************/
.delete-button {
  background-color: #ff4d4f;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff7875;
}
