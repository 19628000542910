/*************************************************
  Modal Backdrop
**************************************************/
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/*************************************************
  Modal Content
**************************************************/
.modal-content {
  background: #ECEFC8;             /* Soft complementary background */
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 80%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.25);
  color: #19485F;                   /* Brand text color */
}

/* Heading style */
.modal-content h2 {
  color: #19485F;
  margin-bottom: 1rem;
}

/*************************************************
  Input Field
**************************************************/
.modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #19485F;
}

/*************************************************
  Modal Actions (Buttons Container)
**************************************************/
.modal-actions {
  display: flex;
  justify-content: space-between;
}

/*************************************************
  Save Button (Primary)
**************************************************/
.save-button {
  /* Gradient button matching other modals */
  background: linear-gradient(to right, #19485F, #2e6c83);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.save-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(25, 72, 95, 0.3);
}

/*************************************************
  Cancel Button (Secondary)
**************************************************/
.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  transition: background 0.3s ease;
}

.cancel-button:hover {
  background-color: #e53935;
}
