/* 1) Base resets and container styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
}

.excel-conversion-container {
  width: 100%;
  min-height: 100vh;
  background-color: #D9E0A4; /* Matches the query form background */
  padding: 2rem 1rem;
  margin-left: 64px; /* Keep if needed for your layout */
}

/* 2) Heading styling */
.header-xlsx {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  font-weight: 700;
  color: #19485F;
  position: relative;
  display: flex;
  justify-content: center;
}

.header-xlsx::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 3px;
  background: #19485F;
  border-radius: 4px;
}

/* 3) Search bar */
.search-bar {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 2rem auto;
  padding: 12px;
  border: 2px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;
  color: #19485F;
  transition: all 0.3s ease;
}
.search-bar:focus {
  border-color: #19485F;
  outline: none;
  box-shadow: 0 0 0 3px rgba(25, 72, 95, 0.2);
}

/* 4) Grid container for cards */
.grid-container-xlsx {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  padding: 0 1rem;
}

/* 5) Card styling */
.card-xlsx {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;  /* Solid white card background */
  border: 2px solid #cccccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  padding: 1.5rem;
  color: #19485F; /* Primary text color */
}

.card-xlsx:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

/* 6) Card content */
.card-body-xlsx {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-title-xlsx {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  color: #19485F;
}

.card-text-xlsx {
  font-size: 1rem;
  color: #19485F;
  line-height: 1.4;
  margin-bottom: 1rem;
}

/* 7) Button styling 
   - Using a similar style as the query ticket submit button
*/
.button {
  padding: 12px 16px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* This mimics the query form gradient button */
.button-outline-light {
  background: linear-gradient(to right, #19485F, #2e6c83);
  color: #fff;
  width: fit-content;
  align-self: flex-start; /* So it doesn't stretch the full card width */
}

.button-outline-light:hover {
  filter: brightness(95%);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(25, 72, 95, 0.3);
}

/* 8) Responsive breakpoints */
@media (max-width: 1200px) {
  .grid-container-xlsx {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .grid-container-xlsx {
    grid-template-columns: repeat(2, 1fr);
  }
  .excel-conversion-container {
    margin-left: 0; /* Adjust if you don’t want the left margin on small screens */
  }
}

@media (max-width: 480px) {
  .grid-container-xlsx {
    grid-template-columns: 1fr;
  }
  .header-xlsx {
    font-size: 1.8rem;
  }
}
