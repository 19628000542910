/*************************************************
  Root Variables
**************************************************/
:root {
  --brand-bg: #ECEFC8;                    /* Soft complementary background */
  --brand-text: #19485F;                  /* Primary brand text color */
  --shadow-color: rgba(0, 0, 0, 0.1);     /* Subtle shadow */
  --highlight-color: rgba(255, 255, 255, 0.3);
  --translucent-bg: rgba(255, 255, 255, 0.3); /* Translucent background for items */
}

/*************************************************
  Main Container
**************************************************/
.view-schools-page {
  margin-left: 64px; 
  padding: 20px;
  width: 100%;
  color: var(--brand-text);
  box-sizing: border-box;
}

/*************************************************
  Header with Decorative Underline
**************************************************/
.view-schools-page h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--brand-text);
  position: relative;
}

.view-schools-page h1::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: var(--brand-text);
  border-radius: 4px;
}

/*************************************************
  Region List Styling
**************************************************/
.region-list {
  margin-top: 20px;
  list-style-type: none;
  padding-left: 0;
}

.outer-list {
  list-style: none;
}

.region-item {
  background: var(--translucent-bg);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 6px var(--shadow-color), 0 -2px 6px var(--highlight-color);
  color: var(--brand-text);
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.2s ease;
}

.region-item:hover {
  box-shadow: 0 6px 12px var(--shadow-color);
  color: #ffcc00; /* Highlight text on hover */
}

.toggle-icon {
  margin-left: 10px;
  cursor: pointer;
}

/*************************************************
  School List Styling
**************************************************/
.school-listv {
  list-style-type: none;
  padding-left: 20px;
  margin-top: 5px;
  background-color: rgba(209, 234, 255, 0.7);
  border-radius: 5px;
}

.school-itemv {
  padding: 8px;
  color: var(--brand-text);
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  transition: background-color 0.2s ease;
}

.school-itemv:hover {
  background-color: rgba(179, 224, 255, 0.7);
}

.school-itemv::before {
  content: '🏫';
  margin-right: 10px;
}

/*************************************************
  Responsive Adjustments
**************************************************/
@media screen and (max-width: 768px) {
  .view-schools-page {
    margin: 34px 0 0 auto;
  }
  .region-list {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  /* Stack region items vertically and align content to the start */
  .region-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px; /* Increase padding for easier tapping */
  }

  /* Position toggle icon at the end of the region item */
  .region-item .toggle-icon {
    align-self: flex-end;
    margin-top: 5px;
  }

  /* Add spacing above the list of schools when a region is expanded */
  .school-listv {
    margin-top: 10px;
    padding-left: 15px;
    background-color: rgba(209, 234, 255, 0.5);
  }

  /* Improve school item touch targets */
  .school-itemv {
    padding: 12px;
    font-size: 1.1rem;
  }

  /* Optional: Increase font size for region names on mobile for better readability */
  .region-item span {
    font-size: 1.2rem;
  }
}

