/*************************************************
  1) Root Variables
**************************************************/
:root {
  --brand-bg: #ECEFC8;                   /* Soft complementary background */
  --brand-text: #19485F;                 /* Primary brand text color */
  --shadow-color: rgba(0, 0, 0, 0.1);    /* Subtle shadow color for neumorphism */
  --highlight-color: rgba(255, 255, 255, 0.3); /* Highlight for light areas */
  --translucent-bg: rgba(255, 255, 255, 0.3);  /* Translucent background for cards */
  --button-hover-bg: #2e6c83;            /* Slightly lighter brand tone on hover */
  --button-active-shadow: inset 2px 2px 5px var(--shadow-color),
                          inset -2px -2px 5px var(--highlight-color); /* Inset shadow on active state */
  --button-box-shadow: 2px 2px 6px var(--shadow-color),
                       -2px -2px 6px var(--highlight-color); /* Default button shadow */
}

/*************************************************
  2) Global Styles
**************************************************/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
  color: var(--brand-text);
}

/*************************************************
  3) Container for the Schools Page
**************************************************/
.school-container {
  margin-left: 95px; /* Consistent with sidebar width */
  padding: 20px;
  text-align: center;
  min-height: 100vh;
  box-sizing: border-box;
}

/*************************************************
  4) Main Heading with Decorative Underline
**************************************************/
.school-container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--brand-text);
  position: relative;
  display: inline-block;
}

/* Decorative underline for the main heading */
.school-container h2::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 4px;
  background: var(--brand-text);
  border-radius: 2px;
}

/*************************************************
  5) Cards Container
**************************************************/
.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px; /* Increased gap for better spacing */
  margin-top: 1rem;
}

/*************************************************
  6) Individual Card Styling
**************************************************/
.card {
  display: flex;
  align-items: center;
  max-width: 40rem;
  width: 100%;
  padding: 3rem 2rem 3rem 1.25rem;
  background: var(--translucent-bg);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 6px var(--shadow-color),
              0 -2px 6px var(--highlight-color);
  border-radius: 1.5rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 6px 12px var(--shadow-color),
              0 -6px 12px var(--highlight-color);
  transform: scale(1.05);
}

/*************************************************
  7) Card Image Preview
**************************************************/
.card-preview {
  position: relative;
}

.card-image {
  max-width: 18rem;
  width: 32vw;
  height: 18rem;
  object-fit: cover;
  margin-left: -3rem;
  border-radius: 1.5rem;
  box-shadow: 2px 2px 6px var(--shadow-color),
              -2px -2px 6px var(--highlight-color);
}

/*************************************************
  8) Card Content and Footer
**************************************************/
.card-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 2rem;
  flex: 1;
}

.card-content {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8; /* Limits text lines for consistency */
}

.card-content h2 {
  color: var(--brand-text);
  margin-bottom: 0.5rem;
  font-size: 2rem;
}

.card-content .description {
  color: var(--brand-text);
  font-size: 1.075rem;
  font-weight: 400;
  line-height: 1.4;
}

.card-footer {
  margin-top: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/*************************************************
  9) Details Button Styling
**************************************************/
.details-button {
  background: var(--brand-text);
  color: #fff;
  padding: 1rem 1.5rem;
  font-size: 1.125rem;
  border-radius: 2.5rem;
  border: none;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  box-shadow: var(--button-box-shadow);
}

.details-button:hover {
  background: var(--button-hover-bg);
}

.details-button:active {
  box-shadow: var(--button-active-shadow);
}

/*************************************************
  10) Responsive Design
**************************************************/
@media (max-width: 768px) {
  .school-container {
    margin-left: 0; /* Remove left margin for smaller screens */
    padding: 20px 10px;
  }

  .cards {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: 2rem 0;
  }

  .card {
    flex-direction: column;
    padding: 2rem;
    width: 90%;
    text-align: center;
  }

  .card-image {
    margin: 0 auto 1.5rem auto;
    width: 100%;
    max-width: 25rem;
    height: auto;
  }

  .card-right {
    margin-left: 0;
  }

  .card-content {
    -webkit-line-clamp: 7; /* Adjust line clamp for smaller screens */
  }

  .card-content h2 {
    font-size: 1.5rem;
  }

  .card-footer {
    justify-content: center;
    margin-top: 1rem;
  }
}

/*************************************************
  11) Additional Enhancements
**************************************************/

/* Ensuring images are responsive */
@media (max-width: 768px) {
  .card-image {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
}

/* Scrollbar Styling for Cards if content overflows */
.cards::-webkit-scrollbar {
  width: 8px;
}

.cards::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 0.1);
  border-radius: 10px;
}

.cards::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.cards::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.cards {
  scrollbar-width: thin;
  scrollbar-color: #888 rgba(241, 241, 241, 0.1);
}
