@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #D9E0A4; /* Unified background with the rest of the app */
}

/*************************************************
  1) Main Container
**************************************************/
.profile-page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
  margin-left: 64px;
  width: 100%;
  color: #19485F;      /* Brand text color */
}

/*************************************************
  2) Profile Content Container
**************************************************/
.profile-content {
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  padding: 30px;
  box-sizing: border-box;
  color: #19485F;
  border-radius: 10px;
}

/*************************************************
  3) Header with Profile Picture
**************************************************/
.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 30px;
}

.profile-pic-container {
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 30px;
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

.camera-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(30%, 30%);
  background: #fff;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  color: #19485F;
  transition: color 0.3s;
}

.camera-icon:hover {
  color: #2e6c83;
}

.profile-info {
  flex: 1;
}

.profile-name {
  margin: 0;
  font-size: 2em;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

/* Decorative underline for the profile name */
.profile-name::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 2px;
  background: #19485F;
  border-radius: 2px;
}

/*************************************************
  4) Info & Document Sections
**************************************************/
.info-section, .document-section {
  background: rgba(255,255,255,0.3);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px 30px;
  margin-bottom: 30px;
  color: #19485F;
}

/* Section Headers */
.info-section-header, .document-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.info-section-header h2, .document-section-header h2 {
  margin: 0;
  font-size: 1.5em;
  color: #19485F;
}

/* Edit Button */
.edit-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #19485F;
  font-size: 1.2em;
  transition: color 0.3s;
}

.edit-btn:hover {
  color: #2e6c83;
}

/* Save and Cancel Buttons */
.save-btn {
  background: linear-gradient(to right, #19485F, #2e6c83);
  color: #fff;
  border: none;
  padding: 8px 14px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: filter 0.3s;
}

.save-btn:hover {
  filter: brightness(95%);
}

.cancel-btn {
  background: #dc3545;
  color: #fff;
  border: none;
  padding: 8px 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.cancel-btn:hover {
  background: #c82333;
}

/*************************************************
  5) Document Upload and Grid
**************************************************/
.upload-label {
  display: inline-block;
  background: linear-gradient(to right, #19485F, #2e6c83);
  color: #fff;
  border-radius: 5px;
  padding: 8px 14px;
  cursor: pointer;
  transition: filter 0.3s;
}

.upload-label:hover {
  filter: brightness(95%);
}

.upload-label i {
  margin-right: 8px;
}

.document-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.document-item {
  background: rgba(255,255,255,0.3);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  color: #19485F;
}

/*************************************************
  6) Responsive Adjustments
**************************************************/
@media screen and (max-width: 768px) {
  .profile-page-container {
    margin-left: 0;
    padding: 10px;
  }
  
  .profile-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .profile-pic-container {
    margin-right: 0;
    margin-bottom: 20px;
  }
  
  .info-section, .document-section {
    padding: 15px;
  }
  
  .document-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 15px;
  }
}
