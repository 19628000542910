/*************************************************
  1) Main Container
**************************************************/
.manage-users-container {
    margin-left: 64px;
    width: 100%;
    padding: 20px;
    
    color: #19485F;      /* Brand text color */
    box-sizing: border-box;
  }
  
  /*************************************************
    2) Header with Decorative Underline
  **************************************************/
  .manage-users-container h1 {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #19485F;
  }
  
  .manage-users-container h1::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 3px;
    background: #19485F;
    border-radius: 4px;
  }
  
  /*************************************************
    3) Message Box for Notifications
  **************************************************/
  .message-box {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f44336;
    color: white;
    padding: 15px;
    border-radius: 5px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .message-box p {
    margin: 0;
  }
  
  .message-box button {
    background-color: white;
    color: #f44336;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .message-box button:hover {
    background-color: #f8d7da;
  }
  
  /*************************************************
    4) Search Bar
  **************************************************/
  .search-bar {
    margin-bottom: 10px;
    padding: 10px;
    width: 50%;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;
    color: #19485F;
  }
  
  /*************************************************
    5) Table Styling
  **************************************************/
  .table-wrapper {
    overflow-x: auto;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .user-table th,
  .user-table td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
    color: #19485F;
  }
  
  .user-table th {
    background-color: #53a1ad;
    font-weight: bold;
    color: white;
  }
  
  .user-table tbody tr {
    background-color: #f1f2f6;
    transition: background-color 0.3s;
  }
  
  .user-table tbody tr:hover {
    background-color: #e2ecff;
  }
  
  .user-table tbody tr:nth-child(even) {
    background-color: #f6f9ff;
  }
  
  .user-table tbody tr:nth-child(even):hover {
    background-color: #e2ecff;
  }
  
  .long-content {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }
  
  .plusIcon {
    text-align: center !important;
  }
  
  /*************************************************
    6) Pagination
  **************************************************/
  .pagination {
    margin-top: 20px;
    text-align: center;
  }
  
  .pagination-button {
    background-color: #333;
    color: #fff;
    border: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination-button.active {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .pagination-button:hover:not(.active) {
    background-color: #ddd;
  }
  
  /*************************************************
    7) Modals (Region/School Assignment & Pages/Features)
  **************************************************/
  /* Overlay for Region/School Assignment Modal */
  .modal-overlay-user {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Content for Region/School Assignment Modal */
  .modal-content-user {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 45%; /* Adjust width as necessary */
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 2000;
  }
  
  .modal-content-user h2 {
    color: #19485F; /* Brand color for the modal heading */
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Header and Footer inside Region/School Modal */
  .modal-header-user {
    font-weight: bold;
    font-size: 20px;
    color: #19485F;
  }
  
  .modal-footer-user {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    padding-top: 15px;
    margin-top: 20px;
  }
  
  /* Buttons inside the region/school modal footer */
  .modal-footer-user button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* "Save" button with a gradient */
  .modal-footer-user button:first-child {
    background: linear-gradient(to right, #19485F, #2e6c83);
    color: white;
  }
  
  .modal-footer-user button:first-child:hover {
    filter: brightness(95%);
  }
  
  /* "Cancel" or secondary button */
  .modal-footer-user button:last-child {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .modal-footer-user button:last-child:hover {
    background-color: #ddd;
  }
  
  /* Region & School List Checkboxes */
  .region-container-user label,
  .school-items-user label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #19485F;
    font-weight: 600;
  }
  
  .region-container-user input[type="checkbox"],
  .school-items-user input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    accent-color: #19485F; /* Brand color for checkbox accent */
  }
  
  /* School List Container */
  .school-list-user {
    padding-left: 30px;
    margin-top: 5px;
    background-color: #f1f2f6;
    border-radius: 5px;
    padding: 10px;
  }
  
  /* Items inside the school list */
  .school-items-user label {
    color: #19485F;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .assign-manager {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  /*************************************************
    8) Feature/Pages Modal
  **************************************************/
  /* Overlay for Feature/Pages Modal */
  .feature-modal-overlay-user {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Content for Feature/Pages Modal */
  .feature-modal-content-user {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 600px; /* Adjust as needed */
    max-height: 80vh;
    overflow-y: auto;
  }
  
  /* Header for Feature/Pages Modal */
  .feature-modal-header-user {
    margin-bottom: 20px;
    font-size: 1.5em;
    text-align: center;
    color: #19485F;
  }
  
  /* Grid layout for the pages list */
  .feature-pages-list-user {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
    color: #19485F;
  }
  
  /* Each feature page checkbox and label */
  .feature-page-item-user label {
    display: flex;
    align-items: center;
    font-size: 1em;
  }
  
  .feature-page-item-user input[type="checkbox"] {
    margin-right: 10px;
    accent-color: #19485F; /* brand color for accent */
  }
  
  /* Modal footer for the feature/pages modal */
  .feature-modal-footer-user {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .feature-modal-footer-user button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .feature-modal-footer-user button:first-child {
    background: linear-gradient(to right, #19485F, #2e6c83);
    color: #fff;
  }
  
  .feature-modal-footer-user button:last-child {
    background-color: #f44336;
    color: #fff;
  }
  
  .feature-modal-footer-user button:hover {
    filter: brightness(95%);
  }
  
  /*************************************************
    9) Responsive Adjustments
  **************************************************/
  @media screen and (max-width: 768px) {
    .manage-users-container {
      margin: 34px 0 0 auto;
      width: 100%;
      padding: 20px;
    }
  
    .user-table th,
    .user-table td {
      padding: 8px;
      font-size: 12px;
    }
  
    .user-table {
      width: 100%;
      overflow-x: auto;
    }
  
    .search-bar {
      width: 45%;
    }
  
    .modal-content-user {
      width: 95%;
    }
  
    .feature-modal-content-user {
      width: 90%;
    }
  
    .feature-pages-list-user {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
    .message-box {
        width: 90% !important;            /* Expand width for mobile screens */
        left: 50% !important;             /* Position from the center horizontally */
        transform: translateX(-50%) !important; /* Center horizontally */
        text-align: center;               /* Center content inside the box */
      }
  }
  
  /*************************************************
    Styling Icons in Table Cells
  **************************************************/
  /* Style buttons containing icons for a consistent look */
  .plusIcon button {
    background: none;
    border: none;
    color: #19485F;              /* Use brand color for icons */
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .plusIcon button:hover {
    color: #2e6c83;              /* Change color on hover for feedback */
  }
  
  /*************************************************
    Styling Dropdowns within Table Cells
  **************************************************/
  .user-table select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #19485F;
    font-size: 0.9rem;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .user-table select:focus {
    border-color: #19485F;
  }
  
  /*************************************************
    Centering Header Cells (Optional)
  **************************************************/
  .user-table th {
    text-align: center;
  }
    