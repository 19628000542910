@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
*{
    box-sizing: border-box;
}
:root{
    --color-highlight:rgb(0 0 0 / 25%);
}
body{
    margin: 0;
    background: #1d1b24;
    color: #D9E0A4;
    height: 100vh;
    font-family: "Varela Round", sans-serif;
    font-weight: 400;
  font-style: normal;
}
.sidebar{
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 64px;
    /* background: #522ad3; */
    transition: width 0.4s;
}
.sidebar nav{
    padding: 0 8px;
}
.header{
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 72px;
    padding: 0 20px 0 8px;
    background: var(--color-highlight);
}
.header img{
    height: 40px;
    margin-left: 9px;
    border-radius: 50%;
}
.header h1,
.sidebar a p {
    position: absolute;
    top: 50%;
    left: 42px;
    translate: 0 -50%;
    opacity: 0;
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    transition: 0.175s;
}
.header h1{
    left: 65px;
    font-size: 20px;
}
.sidebar:hover{
    z-index: 100;
    width: 180px;
}
.sidebar:hover
:is(a p, .header h1){
    opacity: 1;
}
 
.sidebar a{
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 44px;
    width: 100%;
    border-radius: 6px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    padding: 0 0 0 16px;
    opacity: 0.75;
    cursor: pointer;
}
.sidebar a:hover{
    background: var(--color-highlight);
    opacity: 1;
}
.nav-link {
    /* Apply your existing a tag styles here */
    color: #D9E0A4;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }
  
  .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffcc00;
  }
  .checkbox {
    display: none;
  }

  /* Mobile view styles */
@media (max-width: 768px) {
    .sidebar.open{
        background: #19485F;
    }
    .sidebar {
      position: fixed;
      width: 100%;
      height: 100%; /* Adjust based on your design */
      top: 0;
      left: 0;
      transform: translateY(-100%);
      transition: transform 0.3s ease-in-out;
      z-index: 1050;
    }
  
    .sidebar.open {
      transform: translateY(0);
    }
  
    .checkbox {
      display: none;
    }
  
    .toggle {
        display: block;
        position: fixed;
        top: 10px; /* Adjust based on your header */
        right: 10px;
        z-index: 1100; 
        width: 40px;
        cursor: pointer;
        margin: auto;
        display: block;
        height: calc(4px * 3 + 11px * 2);
      }
    
    .bar {
        position: absolute;
        left: 0;
        right: 0;
        height: 4px;
        border-radius: calc(4px / 2);
        background: #ffffff;
        color: inherit;
        opacity: 1;
        transition: none 0.35s cubic-bezier(.5,-0.35,.35,1.5) 0s;
      }
      
      /***** Spin Animation *****/
      
      .bar--top {
        bottom: calc(50% + 11px + 4px/ 2);
        transition-property: bottom,transform;
        transition-delay: calc(0s + 0.35s),0s;
      }
      
      .bar--middle {
        top: calc(50% - 4px/ 2);
        transition-property: opacity;
        transition-delay: calc(0s + 0.35s);
      }
      
      .bar--bottom {
        top: calc(50% + 11px + 4px/ 2);
        transition-property: top,transform;
        transition-delay: calc(0s + 0.35s),0s;
      }
      
      #checkbox:checked + .toggle .bar--top {
        bottom: calc(50% - 4px/ 2);
        transform: rotate(135deg);
        transition-delay: 0s,calc(0s + 0.35s);
      }
      
      #checkbox:checked + .toggle .bar--middle {
        opacity: 0;
        transition-duration: 0s;
        transition-delay: calc(0s + 0.35s);
      }
      
      #checkbox:checked + .toggle .bar--bottom {
        top: calc(50% - 4px/ 2);
        transform: rotate(225deg);
        transition-delay: 0s,calc(0s + 0.35s);
      }
  
    .sidebar nav {
        display: flex;
        flex-direction: column; /* Stack links vertically */
        justify-content: flex-start;
    }
  
    .nav-link {
        text-align: center; /* Center-align the link text */
        padding: 15px;
    }
    .nav-link, .header h1, .sidebar a p {
        opacity: 1; /* Ensure visibility */
    }
    .sidebar:hover{
        width: 100%;
    }
  }
  
  