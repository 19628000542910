/*************************************************
  1) Root Variables
**************************************************/
:root {
    --brand-bg: #ECEFC8;               /* Soft complementary background */
    --brand-text: #19485F;             /* Primary brand text color */
    --shadow-color: rgba(0, 0, 0, 0.1); /* Subtle shadow */
    --highlight-color: rgba(255, 255, 255, 0.3);
    --translucent-bg: rgba(255, 255, 255, 0.3);
    --button-hover-bg: #2e6c83;        /* Slightly lighter brand tone on hover */
  }
  
  /*************************************************
    2) Main Container
  **************************************************/
  .work-report-container {
    margin-left: 64px;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    color: var(--brand-text);
    min-height: 100vh; /* Ensure full viewport coverage */
  }
  
  /*************************************************
    3) Heading with Decorative Underline
  **************************************************/
  .work-report-container h1 {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: var(--brand-text);
    font-weight: 700;
  }
  
  .work-report-container h1::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: var(--brand-text);
    border-radius: 4px;
  }
  
  /*************************************************
    4) Message Box
  **************************************************/
  .message-box {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f44336;
    color: white;
    padding: 15px;
    border-radius: 8px;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
  
  .message-box p {
    margin: 0;
    font-size: 1rem;
  }
  
  .message-box button {
    background-color: white;
    color: #f44336;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
  }
  
  .message-box button:hover {
    background-color: #f8d7da;
  }
  
  /*************************************************
    5) Dropdown Row
  **************************************************/
  .dropdown-row-WorkReportRobotics {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    gap: 5px;
  }
  
  .dropdown-row-WorkReportRobotics select,
  .dropdown-row-WorkReportRobotics input {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: var(--brand-text);
    background: #fff;
    transition: border-color 0.3s;
  }
  
  .dropdown-row-WorkReportRobotics select:focus,
  .dropdown-row-WorkReportRobotics input:focus {
    border-color: var(--brand-text);
  }
  
  /* Hide this label on desktop unless needed */
  #dateInputLabel {
    display: none;
  }
  
  /*************************************************
    6) Buttons Row
  **************************************************/
  .buttons-row-WorkReportRobotics {
    display: flex;
    justify-content: space-around;
    gap: 5px;
    margin-bottom: 20px;
  }
  
  /* Add Description Button */
  .add-description-button-WorkReportRobotics {
    background: linear-gradient(to right, var(--brand-text), var(--button-hover-bg));
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 2px 2px 6px var(--shadow-color),
                -2px -2px 6px var(--highlight-color);
  }
  
  .add-description-button-WorkReportRobotics:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px var(--shadow-color);
  }
  
  /* Export Button */
  .export-button-WorkReportRobotics {
    background: linear-gradient(to right, var(--brand-text), var(--button-hover-bg));
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 2px 2px 6px var(--shadow-color),
                -2px -2px 6px var(--highlight-color);
  }
  
  .export-button-WorkReportRobotics span {
    font-size: 16px;
    margin-right: 10px;
  }
  
  .export-button-WorkReportRobotics:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px var(--shadow-color);
  }
  
  /*************************************************
    7) Table Styling
  **************************************************/
  .table-wrapper-WorkReportRobotics {
    overflow-x: auto;
  }
  
  .table-wrapper-WorkReportRobotics::-webkit-scrollbar {
    height: 6px;
  }
  
  .table-wrapper-WorkReportRobotics::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }
  
  .table-wrapper-WorkReportRobotics::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  .reports-table-WorkReportRobotics {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .reports-table-WorkReportRobotics th,
  .reports-table-WorkReportRobotics td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
    color: var(--brand-text);
    transition: background-color 0.3s;
  }
  
  .reports-table-WorkReportRobotics th {
    background-color: #53a1ad;
    font-weight: bold;
    color: #fff;
  }
  
  .reports-table-WorkReportRobotics tbody tr {
    background-color: #f1f2f6;
  }
  
  .reports-table-WorkReportRobotics tbody tr:hover {
    background-color: #e2ecff;
  }
  
  .reports-table-WorkReportRobotics tbody tr:nth-child(even) {
    background-color: #f6f9ff;
  }
  
  .reports-table-WorkReportRobotics tbody tr:nth-child(even):hover {
    background-color: #e2ecff;
  }
  
  .long-topic-WorkReportRobotics {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }
  
  /*************************************************
    8) Edit and Delete Buttons inside Table
  **************************************************/
  .edit-WorkReportRobotics,
  .delete-WorkReportRobotics {
    background: #0cb9db;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    margin: 2px;
    transition: background-color 0.3s;
  }
  
  .edit-WorkReportRobotics {
    background-color: #0cb9db; /* Edit color */
  }
  
  .edit-WorkReportRobotics:hover {
    background-color: #044c72; /* Darker on hover */
  }
  
  .delete-WorkReportRobotics {
    background-color: #e74c3c; /* Delete color */
  }
  
  .delete-WorkReportRobotics:hover {
    background-color: #c0392b; /* Darker on hover */
  }
  
  /*************************************************
    9) Modal Overlay and Content
  **************************************************/
  .modal-overlay-WorkReportRobotics {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content-WorkReportRobotics {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
    text-align: center;
  }
  
  .modal-content-WorkReportRobotics h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: var(--brand-text);
  }
  
  .modal-input-WorkReportRobotics {
    width: 100%;
    height: 200px;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
  }
  
  .modal-buttons-WorkReportRobotics {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  
  .modal-save-button-WorkReportRobotics,
  .modal-close-button-WorkReportRobotics {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--brand-text);
    color: #fff;
    transition: background-color 0.3s;
  }
  
  .modal-save-button-WorkReportRobotics:hover,
  .modal-close-button-WorkReportRobotics:hover {
    background-color: var(--button-hover-bg);
  }
  
  /*************************************************
    10) Responsive Adjustments
  **************************************************/
  @media screen and (max-width: 768px) {
    #dateInputLabel {
      display: flex;
      margin: 0 auto 8px;
      font-weight: 600;
      color: var(--brand-text);
    }
    
    .work-report-container {
      margin: 34px 0 0 auto;
      padding: 15px;
    }
  
    .dropdown-row-WorkReportRobotics {
      flex-direction: column;
      align-items: center;
    }
  
    .dropdown-row-WorkReportRobotics select,
    .dropdown-row-WorkReportRobotics input {
      width: 90%;
      margin-bottom: 10px;
    }
  
    .reports-table-WorkReportRobotics th,
    .reports-table-WorkReportRobotics td {
      padding: 8px;
      font-size: 12px;
    }
  
    .add-description-button-WorkReportRobotics,
    .export-button-WorkReportRobotics {
      width: 45%;
      font-size: 14px;
      margin-bottom: 10px;
    }
  
    .reports-table-WorkReportRobotics {
      width: 100%;
      overflow-x: auto;
    }
  
    .modal-content-WorkReportRobotics h2 {
      font-size: 1.8rem;
    }
    
    .modal-input-WorkReportRobotics {
      height: 150px;
    }
  }
  