@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #D9E0A4; /* Unified background with the rest of the app */
}

/* Container to center the form */
.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  margin: auto;
}

/* Form styling with brand colors, decorative underline, and shadow */
.auth-container form {
  text-align: center;
  padding: 40px 20px;
  background: #fff;
  border: 3px solid #19485F;
  border-radius: 25px;
  box-shadow: 0 0 30px rgba(25, 72, 95, 0.3);
  position: relative;
  width: 100%;
}

/* Header styling with decorative underline */
.auth-container form h2 {
  font-size: 3em;
  font-weight: 800;
  margin-bottom: 20px;
  color: #19485F;
  position: relative;
}

.auth-container form h2::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 3px;
  background: #19485F;
  border-radius: 4px;
}

.inputbox {
  position: relative;
}

/* Input field styling */
.inputbox .input, .input, .submit {
  width: 85%;
  height: 70px;
  outline: none;
  font-size: 1.3em;
  border: 3px solid rgba(25, 72, 95, 0.6);
  border-radius: 50px;
  background: transparent;
  color: #19485F;
  margin: 10px 0;
  padding-left: 40px;
  padding-right: 80px;
  transition: 0.1s ease-in;
}

.inputbox .input:focus {
  border: 3px solid #19485F;
  background: rgba(25, 72, 95, 0.1);
}

.input::placeholder {
  color: #19485F;
}
.inputbox .icons{
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-50%);
  font-size: 1.8em;
}

/* Gradient submit button styling */
.submit {
  color: #fff;
  background: linear-gradient(to right, #19485F, #2e6c83);
  font-size: 1.8em;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  padding: 0;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.submit:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(25, 72, 95, 0.3);
}

/* Error message styling */
.error {
  color: #ff4d4d;
  font-size: 1em;
  margin-bottom: 15px;
  text-align: center;
}

/* Success message styling */
.success {
  color: #2e6c83;
  font-size: 1em;
  margin-bottom: 15px;
  text-align: center;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .auth-container {
    width: 90%;
    padding: 20px;
  }
  .auth-container form {
    padding: 20px 15px;
  }
  h2 {
    font-size: 2em;
  }
  .inputbox .input, .input, .submit {
    height: 55px;
    font-size: 1em;
    padding-left: 30px;
    padding-right: 50px;
  }
  .submit {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 480px) {
  .auth-container {
    width: 95%;
    padding: 10px;
  }
  .auth-container form {
    padding: 15px 10px;
  }
  h2 {
    font-size: 1.8em;
  }
  .inputbox .input, .input, .submit {
    height: 45px;
    font-size: 0.9em;
    padding-left: 20px;
    padding-right: 40px;
  }
  .submit {
    font-size: 1.2em;
  }
}
.createnew, .olduser {
  margin-top: 20px;
  font-size: 1.2em;
  text-align: center;            /* Center align text and links within these containers */
  color: #19485F;
}

.createnew a.register, .olduser a.login, .createnew a.forgot, .olduser a.forgot {
  color: #19485F;                /* Use brand color for links */
  text-decoration: none;
  font-weight: 600;
  transition: text-decoration 0.3s;
}

.createnew a.register:hover, 
.olduser a.login:hover, 
.createnew a.forgot:hover, 
.olduser a.forgot:hover {
  text-decoration: underline;
}
.instruction{
  color: #19485F;
}
