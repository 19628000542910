/*************************************************
  1) Main Container
**************************************************/
.manage-container {
  margin-left: 64px; /* Same width as sidebar */
  padding: 20px;
  text-align: center;
  width: 100%;
  
  color: #19485F;                /* Brand text color */
  box-sizing: border-box;
}

/*************************************************
  2) Header with Decorative Underline
**************************************************/
.manage-container h1 {
  position: relative;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #19485F;
  display: inline-block;
}

.manage-container h1::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 3px;
  background: #19485F;
  border-radius: 4px;
}

/*************************************************
  3) Grid of Manage Cards
**************************************************/
.manage-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px !important;
}

.manage-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  width: 45%;
  padding: 3rem 2rem;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
  border-radius: 1.5rem;
  transition: all 0.2s ease-in-out;
  text-align: center;
  color: #19485F;                /* Brand text color */
}

.manage-card:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 12px;
  transform: scale(1.05);
}

/*************************************************
  4) Manage Card Headings and Paragraph
**************************************************/
.manage-card h3 {
  margin-bottom: 1rem;
  font-size: 1.75rem;
  color: #19485F;                /* Brand color instead of var(--primary) */
}

.manage-card p {
  font-size: 1.125rem;
  font-weight: 400;
  color: #19485F;                /* Use brand text color for consistency */
}

/*************************************************
  5) Message Box
**************************************************/
.message-box {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  color: white;
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.message-box p {
  margin: 0;
}

.message-box button {
  background-color: white;
  color: #f44336;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.message-box button:hover {
  background-color: #f8d7da;
}

/*************************************************
  6) Responsive Design
**************************************************/
@media (max-width: 768px) {
  .manage-container {
    margin: 34px 0 0 auto;
  }
  
  .manage-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 4rem 0;
  }

  .manage-card {
    width: 100%;
  }
}
