/*************************************************
  1) Main Container
**************************************************/
.employee-wise-robotics-report {
    margin-left: 64px;
    padding: 20px;
    width: 100%;
    
    color: #19485F;                  /* Brand text color */
    box-sizing: border-box;
  }
  
  /*************************************************
    2) Header with Decorative Underline
  **************************************************/
  .employee-wise-robotics-report h1 {
    position: relative;
    text-align: center;
    margin-bottom: 40px;             /* Increased margin for space for underline */
    color: #19485F;
    font-size: 2rem;
    font-weight: 700;
  }
  
  /* Decorative line below header */
  .employee-wise-robotics-report h1::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 3px;
    background: #19485F;
    border-radius: 4px;
  }
  
  /*************************************************
    3) Message Box
  **************************************************/
  .employee-wise-robotics-report-message-box {
    background: #ecf9ec;
    border: 2px solid #2e6c83;
    color: #19485F;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .employee-wise-robotics-report-message-box button {
    background: #dc3545;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .employee-wise-robotics-report-message-box button:hover {
    background: #c82333;
  }
  
  /*************************************************
    4) Dropdown and Date Picker Toggle Row
  **************************************************/
  .employee-wise-robotics-report-dropdown-row {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 5px;
  }
  
  .employee-wise-robotics-report-dropdown-row select,
  .employee-wise-robotics-report-dropdown-row input {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #19485F;
    background: #fff;
  }
  
  /*************************************************
    5) Date Picker Toggle Button
  **************************************************/
  .employee-wise-robotics-report-toggle-date-picker {
    background: none;
    border: none;
    color: #19485F;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 22px;
    display: flex;
    align-items: center;
    transition: text-decoration 0.3s ease;
  }
  
  .employee-wise-robotics-report-toggle-date-picker:hover {
    text-decoration: underline;
  }
  
  /*************************************************
    6) Export Button
  **************************************************/
  .employee-wise-robotics-report-export-button {
    background: linear-gradient(to right, #19485F, #2e6c83);
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 10px auto 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .employee-wise-robotics-report-export-button span {
    font-size: 16px;
    margin-right: 10px;
  }
  
  .employee-wise-robotics-report-export-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(25, 72, 95, 0.3);
  }
  
  /*************************************************
    7) Table Wrapper and Table Styling
  **************************************************/
  .employee-wise-robotics-report-table-wrapper {
    overflow-x: auto;
  }
  
  .employee-wise-robotics-report-reports-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .employee-wise-robotics-report-reports-table th,
  .employee-wise-robotics-report-reports-table td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
    color: #19485F;
  }
  
  .employee-wise-robotics-report-reports-table th {
    background-color: #53a1ad;
    font-weight: bold;
    color: white;
  }
  
  .employee-wise-robotics-report-reports-table tbody tr {
    background-color: #f1f2f6;
    transition: background-color 0.3s;
  }
  
  .employee-wise-robotics-report-reports-table tbody tr:hover {
    background-color: #e2ecff;
  }
  
  .employee-wise-robotics-report-reports-table tbody tr:nth-child(even) {
    background-color: #f6f9ff;
  }
  
  .employee-wise-robotics-report-reports-table tbody tr:nth-child(even):hover {
    background-color: #e2ecff;
  }
  
  /*************************************************
    8) DatePicker Container
  **************************************************/
  .employee-wise-robotics-report-DatePicker {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  /*************************************************
    9) Responsive Adjustments
  **************************************************/
  @media screen and (max-width: 768px) {
    .employee-wise-robotics-report {
      margin: 34px 0 0 auto;
    }
    .employee-wise-robotics-report-dropdown-row {
      flex-direction: column;
      align-items: center;
    }
    .employee-wise-robotics-report-dropdown-row select,
    .employee-wise-robotics-report-dropdown-row input {
      width: 90%;
      margin-bottom: 10px;
    }
    .rdrDateRangePickerWrapper {
      display: block;
    }
    .rdrDateRangeWrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    .rdrDefinedRangesWrapper {
      display: none; /* Hide unnecessary parts on mobile */
    }
    .rdrMonthsVertical {
      overflow: visible;
    }
    .rdrMonthAndYearWrapper {
      margin: 0 auto;
    }
    .rdrMonths {
      flex-direction: column;
    }
    .rdrMonth {
      width: 100% !important;
    }
    .rdrWeekDays {
      justify-content: space-around;
    }
    .rdrNextPrevButton {
      margin: 0 10px;
    }
    .employee-wise-robotics-report-reports-table th,
    .employee-wise-robotics-report-reports-table td {
      padding: 8px;
      font-size: 12px;
    }
    .employee-wise-robotics-report-reports-table {
      width: 100%;
      overflow-x: auto;
    }
  }
  