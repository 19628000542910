/* 1) Reset & base styles (no background on html/body) */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* No background color here */
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
}

/* 2) Container for the form + message box:
   - Full width
   - Minimum height of 100vh so it fills the screen 
   - If content grows beyond 100vh, container will expand 
*/
.query-ticket-contact-container {
  width: 100%;
  min-height: 100vh;
  background-color: #fff7e6;
  display: flex;
  justify-content: flex-start; /* or center, if preferred */
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 2rem 10rem; /* some padding around the form */
}

/* 3) Full-width form wrapper 
   (no max-width, so it occupies the entire container) */
.query-ticket-form-wrapper {
  width: 100%;
}

/* Fade-in animation for form wrapper */
.animate-fadein {
  animation: fadeIn 0.8s ease forwards;
  opacity: 0;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Heading styling */
.query-ticket-form-wrapper h2 {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 700;
  color: #19485F;
  position: relative;
  margin-bottom: 2rem;
}

.query-ticket-form-wrapper h2::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 3px;
  background: #19485F;
  border-radius: 4px;
}

/* 4) Form grouping and layout */
.query-ticket-form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.query-ticket-input-container {
  flex: 1 1 calc(33.33% - 1rem);
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

/* Labels, inputs, selects, textarea */
.query-ticket-input-container label {
  margin-bottom: 0.5rem;
  color: #19485F;
  font-weight: 600;
  font-size: 0.95rem;
}

.query-ticket-input-container input,
.query-ticket-input-container select,
.query-ticket-form-wrapper textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #cccccc;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-size: 1rem;
  background-color: #fff;
  color: #19485F;
}

.query-ticket-input-container input:focus,
.query-ticket-input-container select:focus,
.query-ticket-form-wrapper textarea:focus {
  border-color: #19485F;
  outline: none;
  box-shadow: 0 0 0 3px rgba(25, 72, 95, 0.2);
}

/* Textarea styling */
.query-ticket-form-wrapper textarea {
  resize: none;
  min-height: 120px;
}

/* Error messages */
.error {
  color: #E74C3C;
  font-size: 0.85rem;
  margin-top: 0.5rem;
  animation: fadeIn 0.3s ease, shake 0.3s ease;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  20%, 60% {
    transform: translateX(-5px);
  }
  40%, 80% {
    transform: translateX(5px);
  }
}


@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* 5) Custom select dropdown */
.custom-select-container {
  position: relative;
}

.custom-select-input-form {
  width: 100%;
  padding: 12px;
  border: 2px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  color: #19485F;
}

.custom-select-options-ticket {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 0 0 8px 8px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: scaleDown 0.3s ease forwards;
  transform-origin: top center;
  opacity: 0;
}

@keyframes scaleDown {
  to {
    opacity: 1;
  }
}

.custom-select-option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #19485F;
}

.custom-select-option:hover {
  background-color: #f0f0f0;
}

/* 6) File upload styling */
.file-upload-container {
  border: 2px dashed #3192c3;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  color: #19485F;
}

.file-upload-container:hover {
  border-color: #19485F;
  background-color: #fafafa;
}

.file-upload-title {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.file-upload-input {
  display: none;
}

.file-upload-instructions {
  font-size: 0.85rem;
  color: #555;
}

/* 7) Submit button */
.query-ticket-submit-button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(to right, #19485F, #2e6c83);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.query-ticket-submit-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(25, 72, 95, 0.3);
}

/* Spinner for loading state inside the button */
.querybuttonloader {
  border: 3px solid white;
  border-top-color: transparent;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Slide-in pop-up styling */
.pop-up {
  position: fixed;
  top: -200px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #3c8dbc; /* Success green color */
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  transition: top 0.5s ease;
}

.pop-up.show {
  top: 35%;
  left: 50%;
  transform: translateX(-50%);  
}

.pop-up .icon {
  font-size: 40px;
  margin-bottom: 10px;
}
.pop-up button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #28a745;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pop-up button:hover {
  background-color: #28a745;
  color: white;
}

/* From Uiverse.io by ercnersoy */ 
.bulb svg {
  display: block;
  height: 90px;
  transform-origin: center top;
  animation: swing_31 1.3s ease-in-out infinite alternate;
  fill: #D9E0A4;
}

@keyframes swing_31 {
  0% {
    transform: rotate(18deg);
  }

  100% {
    transform: rotate(-18deg);
  }
}




/* Responsive adjustments */
@media (max-width: 1024px) {
  .query-ticket-input-container {
    flex: 1 1 calc(50% - 1rem);
  }
  .pop-up {
    top: -300px;
  }
  .query-ticket-contact-container {
   
    padding: 2rem 1rem; /* some padding around the form */
  }
}

@media (max-width: 768px) {
  .query-ticket-form-wrapper h2 {
    font-size: 2rem;
  }

  .query-ticket-input-container {
    flex: 1 1 100%;
  }

  .query-ticket-form-group {
    gap: 1rem;
  }

  .custom-select-options {
    max-height: 150px;
  }
  .pop-up {
    top: -300px;
    
  }
  .query-ticket-contact-container {
   
    padding: 2rem 1rem; /* some padding around the form */
  }
}

@media (max-width: 480px) {
  .query-ticket-form-wrapper h2 {
    font-size: 1.8rem;
  }

}

