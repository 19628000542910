.footer {
  width: 100%;
  background: linear-gradient(135deg, #002a47, #005070);
  color: white;
  padding: 25px 16px;
  margin-top: 32px;
  position: relative;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 32px;
  align-items: start;
  padding: 0px 16px;
}

.footer-section {
  text-align: left;
}

.footer-heading {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.underline {
  height: 3px;
  width: 50px;
  background-color: #ffffff;
  margin-bottom: 16px;
}
.follow-us .underline {
  margin: 0 auto 16px auto; /* Center the underline */
}

.contact-info .contact-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
}

.social-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.social-icon:hover {
  background: white;
  color: #002a47;
  transform: scale(1.1);
}

.footer-bottom {
  margin-top: 32px;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 16px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
}

.round-logo {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border: 2px solid white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.round-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.company-name {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 8px;
  text-align: center;
}
.follow-us{
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-section {
    margin-bottom: 24px;
  }

  .social-icons {
    justify-content: center;
  }
}
