/*************************************************
  1) Main Container
**************************************************/
.configure-region-school {
  margin-left: 64px; /* Same width as sidebar */
  padding: 20px;
  width: 100%;
  
  min-height: 100vh; /* Ensure it covers full viewport height */
  color: #19485F; /* Brand text color */
  box-sizing: border-box;
}

/*************************************************
  2) Heading
**************************************************/
.configure-region-school h1 {
  position: relative;          /* Add relative positioning for the pseudo-element */
  text-align: center;
  color: #19485F;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 700;
}

/* Decorative line below the header */
.configure-region-school h1::after {
  content: '';
  position: absolute;
  bottom: -10px;               /* Adjust as needed to position the line */
  left: 50%;
  transform: translateX(-50%);
  width: 90px;                 /* Width of the decorative line */
  height: 3px;                 /* Thickness of the decorative line */
  background: #19485F;         /* Use the brand color */
  border-radius: 4px;
}

/*************************************************
  3) Message Box
**************************************************/
.message-box {
  background: #ecf9ec; /* Light success background */
  border: 2px solid #2e6c83; /* Brand border color */
  color: #19485F; /* Brand text color */
  padding: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-message-button {
  background: #dc3545; /* Red for close */
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.close-message-button:hover {
  background: #c82333;
}

/*************************************************
  4) Add Region Button
**************************************************/
.add-region-button {
  margin-top: 20px;
  margin-bottom: 20px;
  background: linear-gradient(to right, #19485F, #2e6c83); /* Gradient background */
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.add-region-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(25, 72, 95, 0.3);
}

/*************************************************
  5) Region Management
**************************************************/
.region-management ul {
  list-style-type: none;
  padding-left: 0;
}

.region-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #fff;
  color: #19485F;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  transition: box-shadow 0.3s ease, background 0.3s ease, color 0.3s ease;
}

.region-item:hover {
  box-shadow: 0 6px 12px rgba(25, 72, 95, 0.3);
  background: #f0f8ff;
  color: #2e6c83;
}

.region-controls {
  display: flex;
  align-items: center;
}

.add-school-button {
  margin-right: 10px;
  background: linear-gradient(to right, #19485F, #2e6c83);
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.add-school-button:hover {
  background: linear-gradient(to right, #19485F, #2e6c83);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.delete-button {
  background: #f44336; /* Red background */
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  transition: background 0.3s ease;
}

.delete-button:hover {
  background: #e53935;
}
.region-item span {
  color: #19485F; /* Darker color for better visibility */
}


/*************************************************
  6) Toggle Icon
**************************************************/
.toggle-icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  user-select: none;
  transition: color 0.3s ease;
}

.toggle-icon:hover {
  color: #2e6c83;
}

/*************************************************
  7) School List
**************************************************/
.school-list {
  list-style-type: none;
  padding-left: 20px;
  background: #d1eaff;
  border-radius: 5px;
  margin-top: 5px;
}

.school-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #19485F;
  border-bottom: 1px solid #ccc;
}

.school-item:last-child {
  border-bottom: none;
}

.school-item .delete-button {
  background: #f44336;
  color: white;
  border: none;
  padding: 5px 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s ease;
}

.school-item .delete-button:hover {
  background: #e53935;
}

/*************************************************
  8) Loader Component
**************************************************/
.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/*************************************************
  9) Responsive Adjustments
**************************************************/
@media (max-width: 768px) {
  .configure-region-school {
    margin: 34px 10px;
    padding: 15px;
  }

  .add-region-button, .add-school-button, .delete-button, .close-message-button {
    width: 100%;
    margin: 5px 0;
  }

  .region-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .region-controls {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .school-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .school-item .delete-button {
    margin-top: 5px;
  }
}
