/*************************************************
  1) Root Variables (Optional)
**************************************************/
:root {
  --brand-bg: #ECEFC8;               /* Soft complementary background */
  --brand-text: #19485F;             /* Brand text color */
  --shadow-color: rgba(0, 0, 0, 0.1); /* Subtle shadow color */
  --highlight-color: rgba(255, 255, 255, 0.3);
  --background-color: rgba(255, 255, 255, 0.2); /* Slightly translucent white */
}

/*************************************************
  2) Container for the Entire Page
**************************************************/
.query-customer-container-query-chat-customer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh; /* fill the screen vertically */
  overflow: hidden;
  box-sizing: border-box;
}

/*************************************************
  3) Mobile Header for Ticket Details
**************************************************/
.mobile-header-query-chat-customer {
  display: none; /* hidden by default on desktop */
  background-color: var(--background-color);
  color: var(--brand-text);
  padding: 10px;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  box-shadow: 5px 5px 10px var(--shadow-color),
    -5px -5px 10px var(--highlight-color);
}

.mobile-header-query-chat-customer span {
  font-weight: bold;
}

.mobile-header-query-chat-customer span small {
  font-size: 0.8em;
  color: #070707;
  font-weight: normal;
  margin-left: 5px;
}

/*************************************************
  4) Close Modal Button (X Icon)
**************************************************/
.close-modal-btn {
  background: transparent;
  border: none;
  color: var(--brand-text);
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  z-index: 2000;
}

.close-icon-bold {
  color: black !important;
  font-weight: 900;
  font-size: 20px;
}

/*************************************************
  5) Main Content: Ticket Info + Chat
**************************************************/
.ticket-customer-view-query-chat-customer {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

/*************************************************
  6) Ticket Info Section (Left Panel on Desktop)
**************************************************/
.ticket-info-section-query-chat-customer {
  flex: 0 0 30%;
  padding: 20px;
  overflow-y: auto;
  background: var(--background-color);
  box-shadow: inset 5px 5px 10px var(--shadow-color),
    inset -5px -5px 10px var(--highlight-color);
  backdrop-filter: blur(10px);
}

.ticket-info-section-query-chat-customer h2 {
  font-size: 20px;
  color: var(--brand-text);
  text-align: center;
  margin-bottom: 10px;
  position: relative;
}

/* Decorative underline for "Ticket Details" heading */
.ticket-info-section-query-chat-customer h2::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 70px;
  height: 3px;
  background: var(--brand-text);
  border-radius: 3px;
}

/*************************************************
  7) Ticket Info Box
**************************************************/
.ticket-info-box-query-chat-customer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ticket-info-row-query-chat-customer {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 5px;
  box-shadow: 2px 2px 5px var(--shadow-color),
    -2px -2px 5px var(--highlight-color);
}

.info-label-query-chat-customer {
  font-weight: bold;
  color: var(--brand-text);
  margin-right: 5px;
}

.info-value-query-chat-customer {
  color: var(--brand-text);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/*************************************************
  8) Loading State
**************************************************/
.loading-box-query-chat-customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.loading-box-query-chat-customer p {
  margin-top: 10px;
}

.querybuttonloader-query-chat-customer {
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-left-color: transparent;
  border-radius: 50%;
  width: 1.3em;
  height: 1.3em;
  animation: spinloader 1s linear infinite;
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*************************************************
  9) Message History & Chat on the Right
**************************************************/
.message-history-query-chat-customer {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 5px;
  background: var(--background-color);
  box-shadow: 5px 5px 10px var(--shadow-color),
    -5px -5px 10px var(--highlight-color);
  backdrop-filter: blur(10px);
}

/* Heading with underline */
.message-history-title-query-chat-customer {
  position: sticky;
  top: 0;
  background: var(--background-color);
  color: var(--brand-text);
  text-align: center;
  font-size: 20px;
  padding: 10px;
  margin: 0;
  box-shadow: 5px 5px 10px var(--shadow-color),
    -5px -5px 10px var(--highlight-color);
  backdrop-filter: blur(10px);
  z-index: 1;
  position: relative;
}

.message-history-title-query-chat-customer::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 3px;
  background: var(--brand-text);
  border-radius: 3px;
}

/*************************************************
  10) Messages Container
**************************************************/
.messages-container-query-chat-customer {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.messages-container-query-chat-customer.empty-query-chat-customer {
  align-items: center;
  justify-content: center;
}

.status-message-query-chat-customer {
  text-align: center;
  font-size: 16px;
  color: #333;
  margin-top: 20px;
}

/*************************************************
  11) Each Message Item
**************************************************/
.message-item-query-chat-customer {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  max-width: 70%;
  white-space: pre-wrap;
}

.sent-query-chat-customer {
  align-self: flex-end;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 2px 2px 5px var(--shadow-color),
    -2px -2px 5px var(--highlight-color);
}

.received-query-chat-customer {
  align-self: flex-start;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: inset 2px 2px 5px var(--shadow-color),
    inset -2px -2px 5px var(--highlight-color);
}

.message-sender-query-chat-customer {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--brand-text);
}

.message-content-query-chat-customer {
  color: var(--brand-text);
}

.message-timestamp-query-chat-customer {
  font-size: 12px;
  color: #555;
  margin-top: 5px;
  text-align: right;
}

/*************************************************
  12) Attachments in Messages
**************************************************/
.message-attachments-query-chat-customer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.attachment-link-query-chat-customer {
  display: inline-block;
  color: #1a73e8;
  text-decoration: none;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  transition: background-color 0.2s;
}

.attachment-link-query-chat-customer:hover {
  background-color: #f0f8ff;
}

/*************************************************
  13) Input & Send Section
**************************************************/
.message-input-section-query-chat-customer {
  position: relative;
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: inset 2px 2px 5px var(--shadow-color),
    inset -2px -2px 5px var(--highlight-color);
  backdrop-filter: blur(10px);
  background: rgba(255,255,255,0.3);
}

/* Floating container for selected files */
.floating-selected-files-list-query-chat-customer {
  position: absolute;
  bottom: 70px;
  left: 10px;
  right: 10px;
  max-height: 120px;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 2000;
  color: black;
  margin-bottom: 15px;
}

.selected-file-item-query-chat-customer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.3);
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  box-shadow: 2px 2px 5px var(--shadow-color),
    -2px -2px 5px var(--highlight-color);
}

.selected-file-name-query-chat-customer {
  margin-right: 5px;
  word-break: break-all;
}

.remove-file-btn-query-chat-customer {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.remove-file-btn-query-chat-customer:hover {
  color: darkred;
}

.message-textbox-query-chat-customer {
  flex: 1;
  resize: none;
  height: 60px;
  border: none;
  border-radius: 10px;
  padding: 10px;
  background: rgba(255,255,255,0.3);
  outline: none;
  color: var(--brand-text);
  font-size: 14px;
  box-shadow: inset 2px 2px 5px var(--shadow-color),
    inset -2px -2px 5px var(--highlight-color);
}

/*************************************************
  14) Attachment Button
**************************************************/
.attachment-button-query-chat-customer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload-input-query-chat-customer {
  display: none; /* hidden, triggered by label */
}

.attachment-label-query-chat-customer {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0.6em 1em;
  border-radius: 16px;
  font-size: 14px;
  background: rgba(255,255,255,0.3);
  color: var(--brand-text);
  box-shadow: 2px 2px 5px var(--shadow-color),
    -2px -2px 5px var(--highlight-color);
  transition: background-color 0.2s;
}

.attachment-label-query-chat-customer:hover {
  background-color: rgba(255,255,255,0.5);
}

.attachment-label-query-chat-customer:active {
  box-shadow: inset 2px 2px 5px var(--shadow-color),
    inset -2px -2px 5px var(--highlight-color);
}

.attachment-icon-query-chat-customer {
  font-size: 16px;
  margin-right: 4px;
}

/*************************************************
  15) Send Button (with arrow icon)
**************************************************/
.send-button-query-chat-customer {
  font-family: inherit;
  font-size: 14px;
  background: rgba(255,255,255,0.3);
  color: var(--brand-text);
  padding: 0.5em 0.5em;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  box-shadow: 2px 2px 5px var(--shadow-color),
    -2px -2px 5px var(--highlight-color);
}

.send-button-query-chat-customer:hover {
  background: rgba(255,255,255,0.5);
}

.send-button-query-chat-customer:active {
  box-shadow: inset 2px 2px 5px var(--shadow-color),
    inset -2px -2px 5px var(--highlight-color);
}

.send-button-query-chat-customer span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.send-button-query-chat-customer svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.sending-query-chat-customer {
  cursor: not-allowed;
  background: gray;
}

.sending-query-chat-customer .svg-wrapper-query-chat-customer {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.sending-query-chat-customer svg {
  transform: translateX(0.11em) rotate(45deg) scale(1);
}

.sending-query-chat-customer span {
  transform: translateX(0.2em);
}

.svg-wrapper-1-query-chat-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.2em;
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }
  to {
    transform: translateY(-0.1em);
  }
}

/*************************************************
  16) Ticket Details Modal (for mobile view)
**************************************************/
.ticket-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:#ECEFC8; 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.ticket-details-modal-content {
  box-shadow: 5px 5px 10px var(--shadow-color),
    -5px -5px 10px var(--highlight-color);
  border-radius: 10px;
  max-height: 90vh;
  width: 90%;
  overflow-y: auto;
  padding: 20px;
}

/*************************************************
  17) Responsive Media Queries
**************************************************/
@media (max-width: 1024px) {
  /* Hide the ticket info sidebar, show mobile header */
  .ticket-info-section-query-chat-customer {
    display: none;
  }

  .mobile-header-query-chat-customer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .query-customer-container-query-chat-customer {
    height: 100vh; 
    overflow: hidden;
  }

  .ticket-customer-view-query-chat-customer {
    flex-direction: column;
    height: calc(100% - 50px); /* subtract mobile header's height */
  }

  .message-input-section-query-chat-customer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 0;
    z-index: 1000;
  }

  .messages-container-query-chat-customer {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 80px;
    box-sizing: border-box;
  }

  .message-history-query-chat-customer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1; 
    height: 100%;
  }
}
