/*************************************************
  1) Root Variables (Optional)
**************************************************/
:root {
  --brand-bg: #ECEFC8;                   /* Soft complementary background */
  --brand-text: #19485F;                 /* Brand text color */
  --shadow-color: rgba(0, 0, 0, 0.1);    /* Subtle shadow color */
  --highlight-color: rgba(255, 255, 255, 0.3);
  --translucent-bg: rgba(255, 255, 255, 0.3);
  --card-hover-scale: 1.05;             /* How much cards scale on hover */
}

/*************************************************
  2) Container for the Robotics Page
**************************************************/
.robotics-container {
  margin-left: 64px; /* Same width as sidebar */
  padding: 20px;
  text-align: center;
  color: var(--brand-text);
  min-height: 100vh; /* Ensure the background fills the entire viewport height */
  box-sizing: border-box;
}

/*************************************************
  3) Main Heading (with decorative underline)
**************************************************/
.robotics-container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--brand-text);
  position: relative;
  display: inline-block;
}

/* Decorative underline for main heading */
.robotics-container h2::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: var(--brand-text);
  border-radius: 4px;
}

/*************************************************
  4) Cards Container
**************************************************/
.cards-all {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px !important;
  margin-top: 1rem;
}

/*************************************************
  5) Individual Card Styling
**************************************************/
.card {
  display: flex;
  align-items: center;
  max-width: 40rem;
  width: 100%;
  padding: 3rem 2rem 3rem 1.25rem;
  background: var(--translucent-bg);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 6px var(--shadow-color);
  border-radius: 1.5rem;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 6px 12px var(--shadow-color);
  transform: scale(var(--card-hover-scale));
}

/*************************************************
  6) Card Left: Image Preview
**************************************************/
.card-preview {
  position: relative;
}

.card-image {
  max-width: 18rem;
  width: 32vw;
  height: 18rem;
  object-fit: cover;
  margin-left: -3rem;
  border-radius: 1.5rem;
  box-shadow: 2px 2px 6px var(--shadow-color),
    -2px -2px 6px var(--highlight-color);
}

/*************************************************
  7) Card Right: Content & Footer
**************************************************/
.card-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 2rem;
  flex: 1;
}

.card-content {
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8; /* Limits text lines for a consistent layout */
}

.card-content h2 {
  color: var(--brand-text);
  margin-bottom: 0.5rem;
  font-size: 2rem;
}

.card-content .description {
  color: var(--brand-text);
  font-size: 1.075rem;
  font-weight: 400;
  line-height: 1.4;
}

.card-footer {
  margin-top: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: start;
}

/*************************************************
  8) Details Button
**************************************************/
.details-button {
  background: var(--brand-text);
  color: #fff;
  padding: 1rem 1.5rem;
  font-size: 1.125rem;
  border-radius: 2.5rem;
  border: none;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  box-shadow: 2px 2px 6px var(--shadow-color),
    -2px -2px 6px var(--highlight-color);
}

.details-button:hover {
  background: #2e6c83; /* Slightly lighter brand tone on hover */
}

/*************************************************
  9) Responsive Adjustments
**************************************************/
@media (max-width: 768px) {
  .robotics-container {
    margin-left: 0; /* Remove left margin for smaller screens if needed */
    margin-top: 64px; /* If there's a fixed top nav, adjust accordingly */
  }

  .cards-all {
    margin: 2rem 0;
    flex-direction: column;
    align-items: center;
  }

  .card {
    margin: 2rem 0;
    width: 90%;
    padding: 2rem;
    text-align: center;
    flex-direction: column;
  }

  .card-image {
    margin: 0 auto 1rem auto;
    width: 100%;
    max-width: 25rem;
    height: auto;
  }

  .card-right {
    margin-left: 0;
  }

  .card-content {
    -webkit-line-clamp: 7;
  }

  .card h2 {
    font-size: 1.5rem;
  }

  .card-footer {
    justify-content: center;
    margin-top: 1rem;
  }
}
