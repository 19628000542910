.home {
  margin-left: 64px; /* Same width as sidebar */
  padding: 20px;
  color: #19485F;           /* Brand text color */
  box-sizing: border-box;
}

/* Header Styling with Decorative Underline */
.home h2 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  position: relative;
  margin-bottom: 1rem;
  color: #19485F;
}

.home h2::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 3px;
  background: #19485F;
  border-radius: 4px;
}

/* Paragraph Styling */
.home p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-top: 20px;
  text-align: justify;
  color: #19485F;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .home {
    margin: 34px 0 0 auto;
    padding: 15px;
  }
  .home h2 {
    font-size: 2rem;
  }
  .home p {
    font-size: 1rem;
  }
}
