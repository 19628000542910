/* App.css */

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff7e6;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.App {
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

.sidebar {
  background: #19485F;
  color: #D9E0A4;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #fff;
  min-height: 100%;
}
