/*************************************************
  Modal Overlay
**************************************************/
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Semi-transparent dark background for overlay */
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/*************************************************
  Modal Content
**************************************************/
.modal-content {
    
    padding: 20px;
    border-radius: 10px;
    /* Subtle box shadow for depth */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    position: relative;
    width: 400px;
    /* Brand text color */
    color: #19485F;
    /* Optional: add a subtle border to match theme */
    border: 1px solid #19485F;
}

/*************************************************
  Modal Close Button (if used in children)
**************************************************/
.modal-close-button {
    background: #dc3545;  /* Red, consistent with danger buttons in other modals */
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: background 0.3s ease;
}

.modal-close-button:hover {
    filter: brightness(90%);
}
