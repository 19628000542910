/*************************************************
  1) Root Variables
**************************************************/
:root {
    --brand-bg: #ECEFC8;                   /* Soft complementary background */
    --brand-text: #19485F;                 /* Primary brand text color */
    --shadow-color: rgba(0, 0, 0, 0.1);    /* Subtle shadow color */
    --highlight-color: rgba(255, 255, 255, 0.3);
    --translucent-bg: rgba(255, 255, 255, 0.3);
    --button-hover-bg: #2e6c83;
  }
  
  /*************************************************
    2) Main Container
  **************************************************/
  .robotics-date-range-work-report {
    margin-left: 64px;
    padding: 20px;
    width: 100%;
    color: var(--brand-text);
    box-sizing: border-box;
  }
  
  /*************************************************
    3) Header with Decorative Underline
  **************************************************/
  .robotics-date-range-work-report h1 {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: var(--brand-text);
  }
  
  .robotics-date-range-work-report h1::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: var(--brand-text);
    border-radius: 4px;
  }
  
  /*************************************************
    4) Dropdown Row
  **************************************************/
  .dropdown-row-RoboticsDateRangeWorkReport {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 5px;
  }
  
  .dropdown-row-RoboticsDateRangeWorkReport select,
  .dropdown-row-RoboticsDateRangeWorkReport input {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: var(--brand-text);
    background: #fff;
    transition: border-color 0.3s;
  }
  
  .dropdown-row-RoboticsDateRangeWorkReport select:focus,
  .dropdown-row-RoboticsDateRangeWorkReport input:focus {
    border-color: var(--brand-text);
  }
  
  /*************************************************
    5) Toggle Date Picker Button
  **************************************************/
  .toggle-date-picker {
    background: none;
    border: none;
    color: var(--brand-text);
    cursor: pointer;
    padding: 5px 10px;
    font-size: 22px;
    display: flex;
    align-items: center;
    transition: text-decoration 0.3s;
  }
  
  .toggle-date-picker:hover {
    text-decoration: underline;
  }
  
  /*************************************************
    6) Export Button
  **************************************************/
  .export-button-RoboticsDateRangeWorkReport {
    background: linear-gradient(to right, var(--brand-text), var(--button-hover-bg));
    color: #fff;
    border: none;
    border-radius: 5px;
    margin: 10px auto 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 2px 2px 6px var(--shadow-color),
                -2px -2px 6px var(--highlight-color);
  }
  
  .export-button-RoboticsDateRangeWorkReport span {
    font-size: 16px;
    margin-right: 10px;
  }
  
  .export-button-RoboticsDateRangeWorkReport:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px var(--shadow-color);
  }
  
  /*************************************************
    7) Table Styling
  **************************************************/
  .table-wrapper-RoboticsDateRangeWorkReport {
    overflow-x: auto;
  }
  
  .reports-table-RoboticsDateRangeWorkReport {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .reports-table-RoboticsDateRangeWorkReport th,
  .reports-table-RoboticsDateRangeWorkReport td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
    color: var(--brand-text);
  }
  
  .reports-table-RoboticsDateRangeWorkReport th {
    background-color: #53a1ad;
    font-weight: bold;
    color: white;
  }
  
  .reports-table-RoboticsDateRangeWorkReport tbody tr {
    background-color: #f1f2f6;
    transition: background-color 0.3s;
  }
  
  .reports-table-RoboticsDateRangeWorkReport tbody tr:hover {
    background-color: #e2ecff;
  }
  
  .reports-table-RoboticsDateRangeWorkReport tbody tr:nth-child(even) {
    background-color: #f6f9ff;
  }
  
  .reports-table-RoboticsDateRangeWorkReport tbody tr:nth-child(even):hover {
    background-color: #e2ecff;
  }
  
  .long-topic-RoboticsDateRangeWorkReport {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }
  
  /*************************************************
    8) DatePicker Container
  **************************************************/
  .DatePicker {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  /*************************************************
    9) Responsive Adjustments
  **************************************************/
  @media screen and (max-width: 768px) {
    .robotics-date-range-work-report {
      margin: 34px 0 0 auto;
    }
  
    .dropdown-row-RoboticsDateRangeWorkReport {
      flex-direction: column;
      align-items: center;
    }
    
    .dropdown-row-RoboticsDateRangeWorkReport select,
    .dropdown-row-RoboticsDateRangeWorkReport input {
      width: 90%;
      margin-bottom: 10px;
    }
  
    .rdrDateRangePickerWrapper {
      display: block;
    }
    .rdrDateRangeWrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
    .rdrDefinedRangesWrapper {
      display: none;
    }
    .rdrMonthsVertical {
      overflow: visible;
    }
    .rdrMonthAndYearWrapper {
      margin: 0 auto;
    }
    .rdrMonths {
      flex-direction: column;
    }
    .rdrMonth {
      width: 100% !important;
    }
    .rdrWeekDays {
      justify-content: space-around;
    }
    .rdrNextPrevButton {
      margin: 0 10px;
    }
    .reports-table-RoboticsDateRangeWorkReport th,
    .reports-table-RoboticsDateRangeWorkReport td {
      padding: 8px;
      font-size: 12px;
    }
    .reports-table-RoboticsDateRangeWorkReport {
      width: 100%;
      overflow-x: auto;
    }
  }
  