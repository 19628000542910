/* Use #D9E0A4 for backgrounds and #19485F for text */
* {
  box-sizing: border-box;
}

:root {
  /* Main background color */
  --background-color: #D9E0A4;
  /* A subtle shadow color related to #19485F (slightly transparent) */
  --shadow-color: rgba(25, 72, 95, 0.3);
  /* A light highlight color */
  --highlight-color: rgba(255, 255, 255, 0.3);
  /* Main text color */
  --text-color: #19485F;
}

.query-container {
  display: flex;
  flex-direction: column;
  margin-left: 64px;
  padding: 20px;
  width: calc(100% - 64px);
  height: 100vh;
  box-sizing: border-box;
}

.ticket-list-container {
  display: flex;
  background: var(--background-color);
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

/* Message history styles */
.message-history {
  height: 540px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 5px;
  background: var(--background-color);
  box-shadow: inset 5px 5px 10px var(--shadow-color),
              inset -5px -5px 10px var(--highlight-color);
  backdrop-filter: blur(10px);
}

.message-history-title {
  position: sticky;
  color: var(--text-color);
  top: 0;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 5px 5px 10px var(--shadow-color),
              -5px -5px 10px var(--highlight-color);
  backdrop-filter: blur(10px);
}

.messages-container {
  overflow-y: auto;
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.messages-container.empty {
  align-items: center;
  justify-content: center;
}

.status-message {
  font-size: 18px;
  color: var(--text-color);
  text-align: center;
}

.message-item {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
  max-width: 70%;
  color: var(--text-color);
}

/* Sent messages */
.message-item.sent {
  align-self: flex-end;
  background: var(--background-color);
  box-shadow: 5px 5px 10px var(--shadow-color),
              -5px -5px 10px var(--highlight-color);
  backdrop-filter: blur(10px);
}

/* Received messages */
.message-item.received {
  align-self: flex-start;
  background: var(--background-color);
  box-shadow: inset 5px 5px 10px var(--shadow-color),
              inset -5px -5px 10px var(--highlight-color);
  backdrop-filter: blur(10px);
}

.message-sender {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--text-color);
  font-size: 13px;
}

.message-content {
  white-space: pre-wrap;
  color: var(--text-color);
  font-size: 13px;
}

.message-timestamp {
  font-size: 10px;
  color: var(--text-color);
  margin-top: 5px;
  text-align: right;
}

/* Styles for the message input section */
.message-input-section {
  background: var(--background-color);
  padding: 10px;
  margin: 3px;
  box-shadow: inset 5px 5px 10px var(--shadow-color),
              inset -5px -5px 10px var(--highlight-color);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.message-textbox {
  width: 100%;
  height: 70px;
  resize: none;
  padding: 10px;
  font-size: 14px;
  background: var(--background-color);
  border: none;
  border-radius: 10px;
  box-shadow: inset 5px 5px 10px var(--shadow-color),
              inset -5px -5px 10px var(--highlight-color);
  margin-bottom: 10px;
  color: var(--text-color);
  backdrop-filter: blur(10px);
}

.message-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message-options label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--text-color);
}

.toggler {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.toggler input {
  display: none;
}

.toggler label {
  display: block;
  position: relative;
  width: 40px;
  height: 26px;
  border-radius: 26px;
  background: var(--background-color);
  cursor: pointer;
  box-shadow: inset 2px 2px 5px var(--shadow-color),
              inset -2px -2px 5px var(--highlight-color);
}

.toggler label::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 30%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #d7062a;
  transform: translate(-50%, -50%);
  transition: left 0.2s ease, background-color 0.2s ease;
  box-shadow: 2px 2px 5px var(--shadow-color),
              -2px -2px 5px var(--highlight-color);
}

.toggler input:checked + label::after {
  left: 70%;
  background-color: #50ac5d;
}

.toggler .toggler-on,
.toggler .toggler-off {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.toggler input:checked + label .toggler-on {
  opacity: 1;
}

.toggler input:not(:checked) + label .toggler-off {
  opacity: 1;
}

.toggler .path {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.toggle-label {
  margin-left: 8px;
  font-size: 14px;
  color: var(--text-color);
}

.send-button {
  font-family: inherit;
  font-size: 15px;
  background: var(--background-color);
  color: var(--text-color);
  padding: 0.5em 0.5em;
  padding-left: 0.5em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  box-shadow: 5px 5px 10px var(--shadow-color),
              -5px -5px 10px var(--highlight-color);
  backdrop-filter: blur(10px);
}

.send-button:active {
  box-shadow: inset 5px 5px 10px var(--shadow-color),
              inset -5px -5px 10px var(--highlight-color);
}

.send-button span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.send-button svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.send-button.sending {
  cursor: not-allowed;
  background: gray;
}

.sending .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.sending svg {
  transform: translateX(0.11em) rotate(45deg) scale(1);
}

.sending span {
  transform: translateX(0.2em);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }
  to {
    transform: translateY(-0.1em);
  }
}

.messages-container::-webkit-scrollbar {
  width: 6px;
}

.messages-container::-webkit-scrollbar-track {
  background: var(--background-color);
  border-radius: 10px;
}

.messages-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.messages-container {
  scrollbar-width: thin;
  scrollbar-color: #888 var(--background-color);
}

.querybuttonloader {
  border: 4px solid rgba(25, 72, 95, 0.2);
  border-left-color: transparent;
  border-radius: 50%;
  margin-right: 0.5em;
  width: 1.1em;
  height: 1.1em;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Sidebar styles */
.query-sidebar-left {
  flex: 0 0 15%;
  width: 15%;
  background: var(--background-color);
  box-shadow: 5px 5px 10px var(--shadow-color),
              -5px -5px 10px var(--highlight-color);
  border-right: none;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  backdrop-filter: blur(10px);
}

.query-sidebar-right {
  flex: 0 0 30%;
  width: 25%;
  background: var(--background-color);
  border-left: 1px solid rgba(25, 72, 95, 0.2);
  backdrop-filter: blur(10px);
}

.query-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: var(--background-color);
  box-shadow: 5px 5px 10px var(--shadow-color),
              -5px -5px 10px var(--highlight-color);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

/* User section styles */
.query-user-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background: var(--background-color);
  z-index: 1;
  padding: 10px;
  box-shadow: inset 5px 5px 10px var(--shadow-color),
              inset -5px -5px 10px var(--highlight-color);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.query-user-section p {
  color: var(--text-color);
  font-weight: bold;
  font-size: 1.5em;
}

.query-user-section hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.query-views-section-left {
  padding: 10px;
}

.query-views-section h3 {
  color: var(--text-color);
  margin-bottom: 20px;
}

.select-ticket {
  font-size: 18px;
  color: #555;
  text-align: center;
  margin-top: 10px;
}

.query-views-list li {
  padding: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: var(--text-color);
  background: var(--background-color);
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 5px 5px 10px var(--shadow-color),
              -5px -5px 10px var(--highlight-color);
  backdrop-filter: blur(10px);
}

.query-views-list li.active {
  background-color: rgba(25, 72, 95, 0.25);
}

.query-views-list li:hover {
  background-color: rgba(25, 72, 95, 0.25);
}

tbody tr:hover {
  background-color: rgba(25, 72, 95, 0.2);
}

.query-views-list-right li {
  padding: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: var(--text-color);
  font-weight: bold;
  margin-bottom: 10px;
}

.query-tag {
  display: block;
  font-weight: bold;
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 5px;
}

.query-detail {
  display: block;
  font-weight: normal;
  font-size: 13px;
  color: var(--text-color);
}

h2.query-views-title {
  color: var(--text-color);
  font-size: 22px;
  margin-bottom: 10px;
}

.ticket-table {
  background-color: rgba(217, 224, 164, 0.3);
  border-radius: 8px;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  backdrop-filter: blur(10px);
}

.ticket-table-Table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  cursor: pointer;
}

.ticket-table-Table th,
.ticket-table-Table td {
  padding: 15px;
  border-bottom: 1px solid rgba(25, 72, 95, 0.4);
  text-align: left;
  font-size: 14px;
  color: var(--text-color);
}

.ticket-table-Table th {
  background-color: rgba(217, 224, 164, 0.45);
}

.table-body-scroll {
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}

.table-body-scroll::-webkit-scrollbar {
  width: 8px;
}

.table-body-scroll::-webkit-scrollbar-track {
  background: rgba(217, 224, 164, 0.3);
  border-radius: 10px;
}

.table-body-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.table-body-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* For Firefox */
.table-body-scroll {
  scrollbar-width: thin;
  scrollbar-color: #888 rgba(217, 224, 164, 0.3);
}

.query-sidebar-left,
.query-sidebar-right {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.query-sidebar-left::-webkit-scrollbar,
.query-content::-webkit-scrollbar,
.query-sidebar-right::-webkit-scrollbar {
  width: 8px;
}

.query-sidebar-left::-webkit-scrollbar-track,
.query-content::-webkit-scrollbar-track,
.query-sidebar-right::-webkit-scrollbar-track {
  background: rgba(217, 224, 164, 0.3);
  border-radius: 10px;
}

.query-sidebar-left::-webkit-scrollbar-thumb,
.query-content::-webkit-scrollbar-thumb,
.query-sidebar-right::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.query-sidebar-left::-webkit-scrollbar-thumb:hover,
.query-content::-webkit-scrollbar-thumb:hover,
.query-sidebar-right::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.query-sidebar-left,
.query-content,
.query-sidebar-right {
  scrollbar-width: thin;
  scrollbar-color: #888 rgba(217, 224, 164, 0.3);
}

.assigned-to-container,
.status-container {
  position: relative;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid rgba(25, 72, 95, 0.4);
  border-radius: 5px;
}

.assigned-to-name,
.status-name {
  display: block;
  color: var(--text-color);
  font-size: 14px;
}

.custom-select-input {
  width: 100%;
  padding: 5px;
  border: 1px solid rgba(25, 72, 95, 0.4);
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: rgba(217, 224, 164, 0.5);
  color: var(--text-color);
}

.custom-select-options,
.custom-status-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgba(217, 224, 164, 0.9);
  border: 1px solid rgba(25, 72, 95, 0.4);
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  backdrop-filter: blur(10px);
}

.custom-select-option,
.custom-status-option {
  padding: 5px 10px;
  cursor: pointer;
  color: var(--text-color);
}

.custom-select-option:hover,
.custom-status-option:hover {
  background-color: rgba(25, 72, 95, 0.15);
}

/* Container for the search box and filters */
.filters-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Search box styling */
.filters-container .search-box {
  flex: 1;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 50px;
  border: none;
  outline: none;
  background: var(--background-color);
  box-shadow: inset 5px 5px 10px var(--shadow-color),
              inset -5px -5px 10px var(--highlight-color);
  margin-right: 15px;
  color: var(--text-color);
  backdrop-filter: blur(10px);
}

/* Region filter container */
.region-filter {
  position: relative;
  margin-right: 15px;
}

/* Select dropdown styling */
.region-filter select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 50px;
  border: none;
  outline: none;
  background: rgba(217, 224, 164, 0.5);
  box-shadow: 5px 5px 10px var(--shadow-color),
              -5px -5px 10px var(--highlight-color);
  cursor: pointer;
  color: var(--text-color);
  backdrop-filter: blur(10px);
}

/* Clear filter button styling */
.clear-filter-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 14px;
  color: #888;
  cursor: pointer;
}

/* My Queries button styling */
.my-queries-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 50px;
  border: none;
  outline: none;
  background: rgba(217, 224, 164, 0.5);
  box-shadow: 5px 5px 10px var(--shadow-color),
              -5px -5px 10px var(--highlight-color);
  cursor: pointer;
  color: var(--text-color);
  backdrop-filter: blur(10px);
}

.my-queries-button.active {
  background: rgba(25, 72, 95, 0.3);
  box-shadow: inset 5px 5px 10px var(--shadow-color),
              inset -5px -5px 10px var(--highlight-color);
}

.my-message-button{
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 50px;
  border: none;
  outline: none;
  background: rgba(217, 224, 164, 0.5);
  box-shadow: 5px 5px 10px var(--shadow-color),
              -5px -5px 10px var(--highlight-color);
  cursor: pointer;
  color: var(--text-color);
  backdrop-filter: blur(10px);
  margin-left: 10px;
}
.my-message-button.active {
  background: rgba(25, 72, 95, 0.3);
  box-shadow: inset 5px 5px 10px var(--shadow-color),
              inset -5px -5px 10px var(--highlight-color);
}

.message-attachments {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Individual attachment link */
.attachment-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 8px 12px;
  transition: background-color 0.3s, box-shadow 0.3s;
  color: var(--text-color);
}

/* Attachment text */
.attachment-text {
  font-size: 14px;
}

/* Hover effects for attachment links */
.attachment-link:hover {
  background-color: rgba(217, 224, 164, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.attachment-button-query-dashboard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload-input-query-dashboard {
  display: none; /* hidden, triggered by label */
}

.attachment-label-query-dashboard {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0.6em 1em;
  border-radius: 16px;
  font-size: 14px;
  background: rgba(255,255,255,0.3);
  color: var(--brand-text);
  box-shadow: 2px 2px 5px var(--shadow-color),
    -2px -2px 5px var(--highlight-color);
  transition: background-color 0.2s;
}

.attachment-label-query-dashboard:hover {
  background-color: rgba(255,255,255,0.5);
}

.attachment-label-query-dashboard:active {
  box-shadow: inset 2px 2px 5px var(--shadow-color),
    inset -2px -2px 5px var(--highlight-color);
}

.attachment-icon-query-dashboard {
  font-size: 16px;
  margin-right: 4px;
}
.floating-selected-files-list-query-dashboard {
  position: absolute;
  bottom: 70px;
  left: 10px;
  right: 10px;
  max-height: 120px;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 2000;
  color: black;
  margin-bottom: 75px;
}

.selected-file-item-query-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.3);
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  box-shadow: 2px 2px 5px var(--shadow-color),
    -2px -2px 5px var(--highlight-color);
}

.selected-file-name-query-dashboard {
  margin-right: 5px;
  word-break: break-all;
}

.remove-file-btn-query-dashboard {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.remove-file-btn-query-dashboard:hover {
  color: darkred;
}
