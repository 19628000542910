/*************************************************
  1) Main Container
**************************************************/
.lesson-planner-container {
    margin-left: 64px;
    width: 100%;
    padding: 20px;
    
    color: #19485F;             /* Brand text color */
    box-sizing: border-box;
  }
  
  /*************************************************
    2) Header with Decorative Underline
  **************************************************/
  .lesson-planner-container h1 {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: 700;
    color: #19485F;
  }
  
  .lesson-planner-container h1::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 3px;
    background: #19485F;
    border-radius: 4px;
  }
  
  /*************************************************
    3) Message Box
  **************************************************/
  .message-box {
    background: #ecf9ec;
    border: 2px solid #2e6c83;
    color: #19485F;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /*************************************************
    4) Dropdown Row Styling
  **************************************************/
  .dropdown-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    gap: 5px;
  }
  
  .dropdown-row select {
    width: 30%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #19485F;
    background: #fff;
  }
  
  /*************************************************
    5) Buttons Row
  **************************************************/
  .buttons-row {
    display: flex;
    justify-content: space-around;
    gap: 5px;
  }
  
  /* Gradient Add Topic Button */
  .add-topic-button {
    background: linear-gradient(to right, #19485F, #2e6c83);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .add-topic-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(25, 72, 95, 0.3);
  }
  
  /* Gradient Excel Button */
  .excel-button {
    background: linear-gradient(to right, #19485F, #2e6c83);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .excel-button span {
    font-size: 16px;
    margin-right: 10px;
  }
  
  .excel-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(25, 72, 95, 0.3);
  }
  
  /*************************************************
    6) Table and Related Styling
  **************************************************/
  .table-wrapper {
    overflow-x: auto;
  }
  
  .topics-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .topics-table th,
  .topics-table td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
    font-size: 14px;
    color: #19485F;
  }
  
  .topics-table th {
    background-color: #53a1ad;
    color: white;
    font-weight: bold;
  }
  
  .long-topic {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }
  
  .topics-table tbody tr {
    background-color: #f1f2f6;
    transition: background-color 0.3s;
  }
  
  .topics-table tbody tr:hover {
    background-color: #e2ecff;
  }
  
  .topics-table tbody tr:nth-child(even) {
    background-color: #f6f9ff;
  }
  
  .topics-table tbody tr:nth-child(even):hover {
    background-color: #e2ecff;
  }
  
  /*************************************************
    7) Delete Button
  **************************************************/
  .delete-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  .delete-button:hover {
    background-color: #c0392b;
  }
  
  .delete-button:focus {
    outline: none;
  }
  
  /*************************************************
    8) Pagination Controls
  **************************************************/
  .pagination {
    margin-top: 20px;
    text-align: center;
  }
  
  .pagination-button {
    background-color: #333;
    color: #fff;
    border: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination-button.active {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .pagination-button:hover:not(.active) {
    background-color: #ddd;
  }
  
  /*************************************************
    9) Responsive Adjustments
  **************************************************/
  @media screen and (max-width: 768px) {
    .lesson-planner-container {
      margin: 34px 0 0 auto;
    }
    
    .dropdown-row {
      flex-direction: column;
      align-items: center;
    }
  
    .dropdown-row select {
      width: 90%;
      margin-bottom: 10px;
    }
  
    .add-topic-button, .excel-button {
      width: 90%;
      margin-bottom: 10px;
    }
  
    .topics-table th, .topics-table td {
      padding: 8px;
      font-size: 12px;
    }
  
    .topics-table {
      width: 100%;
      overflow-x: auto;
    }
    
    .modal-content {
      width: 90%; /* Adjust modal width on smaller screens if necessary */
    }
  }
  